import React, { useState, useEffect, useCallback } from "react";
import { firebaseFunctions } from "../firebase";
import { isAccountAuthorised } from '../composants/accreditations';
import {
  httpsCallable
  // , connectFunctionsEmulator
} from "firebase/functions";
import { useLocation } from 'react-router-dom';

/**
 * La page admin permet de gérer les utilisateurs du bar
 */
const AdminPage = () => {

  // Comment before commit on main
  // connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001);

  const [isLoading, setIsLoading] = useState(true);
  const [usersHTML, setUserHTML] = useState(<div></div>);
  const [currentlyEditedUser, setUser] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAdminPopupOpen, setIsAdminPopupOpen] = useState(false);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);

  const firebaseEchoTest = httpsCallable(firebaseFunctions, 'echoTest');
  const firebaseListUsersFromBar = httpsCallable(firebaseFunctions, 'listUsersFromBar');
  const firebaseUpdateUser = httpsCallable(firebaseFunctions, 'updateUser');
  const firebaseAddUser = httpsCallable(firebaseFunctions, 'addUser');
  const firebaseDeleteUser = httpsCallable(firebaseFunctions, 'deleteUser');


  const location = useLocation();
  const url = location.pathname.split('/');
  const barName = url[1];

  // cette fonction permet de récupérer tous les utilisateurs du bar
  const getAllBarUsers = useCallback(async () => {

    firebaseEchoTest({ text: "Hello, World!" })
      .then((result) => {
        console.log(result.data.text);
      })
      .catch((error) => {
        console.log(error);
      });

    firebaseListUsersFromBar({ text: barName })
      .then((result) => {
        console.log(result.data);

        setUserHTML(displayUsers(JSON.parse(result.data)));
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barName]);

  // cette fonction permet d'ouvrir le popup pour modifier un utilisateur
  function editUser(userUid) {

    setUser(userUid);

    const adminEmail = "admin." + barName + "@barashots.ch";
    if (userUid.email === adminEmail) {
      setIsAdminPopupOpen(true);
    } else {
      setIsPopupOpen(true);
    }
  }

  const closePopup = () => {
    setIsPopupOpen(false);
    setIsAdminPopupOpen(false);
    setIsAddPopupOpen(false);
  }

  // cette fonction permet de modifier un utilisateur
  const submitUserEdit = () => {
    const newEmail = document.getElementById("email").value + "." + barName + "@barashots.ch";
    const newPassword = document.getElementById("password").value;

    firebaseUpdateUser({
      uid: currentlyEditedUser.uid,
      email: newEmail,
      password: newPassword
    })
      .then((updateUser) => {
        console.log('Successfully updated user:\n', JSON.stringify(updateUser.data, null, 2));
        closePopup();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        closePopup();
      });

  }

  // cette fonction permet de modifier un utilisateur admin
  const submitAdminUserEdit = () => {
    const newPassword = document.getElementById("password").value;

    firebaseUpdateUser({
      uid: currentlyEditedUser.uid,
      email: currentlyEditedUser.email,
      password: newPassword
    })
      .then((updateUser) => {
        console.log('Successfully updated admin user:\n', JSON.stringify(updateUser.data, null, 2));
        closePopup();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        closePopup();
      });
  }

  // cette fonction permet de supprimer un utilisateur
  const deleteUser = () => {

    firebaseDeleteUser({
      uid: currentlyEditedUser.uid,
    })
      .then((updateUser) => {
        console.log('Successfully deleted user:\n', JSON.stringify(updateUser.data, null, 2));
        closePopup();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        closePopup();
      });
  }

  const addUserPopup = () => {
    setIsAddPopupOpen(true);
  }

  // cette fonction permet d'ajouter un utilisateur
  const submitAddUser = () => {
    const email = document.getElementById("email").value + "." + barName + "@barashots.ch";
    const password = document.getElementById("password").value;

    firebaseAddUser({
      email: email,
      password: password
    })
      .then((newUser) => {
        console.log('Successfully added user:\n', JSON.stringify(newUser.data, null, 2));
        closePopup();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        closePopup();
      });
  }

  // cette fonction permet d'afficher les utilisateurs
  function displayUsers(users) {
    return users.map((user) => {
      return (
        <div key={user.uid}>
          <p>{user.email}</p>
          <button type="button" onClick={() => editUser(user)}>Edit</button>
        </div>
      );
    });
  }

  // cette fonction permet de charger la page
  useEffect(() => {
    // Vérifie que l'utilisateur est bien connecté et qu'il est autorisé à accéder à la page
    if (!isAccountAuthorised(barName, true)) {
      // console.warn("User not authorised to access this page, " +
      //   "uncomment the following line to redirect to login page " +
      //   "before commiting on main");
      window.location.href = `/#/login`;
    }

    getAllBarUsers(); // Call the function when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [getAllBarUsers, barName]);

  // HTML de la page
  return (
    <div>
      <h1>Admin Page</h1>
      <div>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div>
            {usersHTML}
            <div>
              <button type="button" onClick={() => addUserPopup()}>Add user</button>
            </div>
          </div>
        )}
      </div>
      <div>
        {isPopupOpen ? (
          <div className="container">
            <div className="popup-overlay">
              <div className="popup-content">
                <form >
                  <div className="input_box">
                    <label  >New email:</label>
                    <input type="text" id="email" />
                    <label>.{barName}@barashots.ch</label>
                  </div>
                  <div className="input_box">
                    <label >New password:</label>
                    <input type="password" id="password" />
                  </div>

                  <div className="buttons_bottom">
                    <button id="closePopup" onClick={closePopup}>Cancel</button>
                    <button type="submit" onClick={submitUserEdit}>Submit</button>
                    <button id="delete" onClick={deleteUser}>Delete</button>
                  </div>

                </form>

              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {isAdminPopupOpen ? (
          <div className="container">
            <div className="popup-overlay">
              <div className="popup-content">
                <form >
                  <div className="input_box">
                    <label >New password:</label>
                    <input type="password" id="password" />
                    <label>.{barName}@barashots.ch</label>
                  </div>

                  <div className="buttons_bottom">
                    <button id="closePopup" onClick={closePopup}>Cancel</button>
                    <button type="submit" onClick={submitAdminUserEdit}>Submit</button>
                  </div>

                </form>

              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {isAddPopupOpen ? (
          <div className="container">
            <div className="popup-overlay">
              <div className="popup-content">
                <form >
                  <div className="input_box">
                    <label  >Email:</label>
                    <input type="text" id="email" />
                    <label>.{barName}@barashots.ch</label>
                  </div>
                  <div className="input_box">
                    <label >Password:</label>
                    <input type="password" id="password" />
                  </div>

                  <div className="buttons_bottom">
                    <button id="closePopup" onClick={closePopup}>Cancel</button>
                    <button type="submit" onClick={submitAddUser}>Create</button>
                  </div>

                </form>

              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default AdminPage;