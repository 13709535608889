import React from "react";
import "./styles/add_drink.css";
import { firebaseDb, firebaseStorage } from "../../firebase";
import { collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import { useLocation } from "react-router-dom";

/**
 * Ce popup est utilisé pour modifier une boisson du menu
 *  
 * props : onClose
 * onClose : fonction pour fermer le popup
 */
const EditDrink = (props) => {
  const location = useLocation();

  // separe l'url pour recuperer le nom du bar
  const url = location.pathname.split("/");
  const barName = url[1];

  const registerDrink = (event) => {
    event.preventDefault();
    const collectionRef = collection(firebaseDb, "BaraShots_Bara");
    // recherche le bar dans la base de donnée
    const q = query(collectionRef, where("name", "==", barName));
    var barID = "";
    getDocs(q).then((response) => {
      if (response.empty) {
        console.log('No bar found from the URL.');
        return;
      } else {
        if (response.size > 1) {
          console.log('More than one bar found from the URL.');
          return;
        } else {
          barID = response.docs[0].id;
          getDocs(collection(firebaseDb, "BaraShots_Bara", barID.toString(), "Menu"))
            .then((result) => {
              result.forEach((doc) => {
                // recherche la boisson dans la base de donnée
                if (props.drink.Name === doc.data().Name) {
                  const docRef = doc.ref;

                  const fileInput = document.getElementById('image').files[0];
                  if (fileInput === undefined) {
                    updateDoc(docRef, {
                      Name: document.getElementById('name').value,
                      Price: document.getElementById('price').value,
                      Description: document.getElementById('description').value,
                      Category: document.getElementById('category').value,
                    }).then(() => {
                      window.location.reload();
                    });
                    props.onClose();
                    return;
                  }

                  // efface l'ancienne image
                  const prevRef = doc.data().ImageRef;
                  const storageRef = ref(firebaseStorage, prevRef);
                  console.log('Previous image ref: ', prevRef);
                  deleteObject(storageRef).then(() => {
                  }).catch((error) => {
                    console.log('Error while deleting the previous image: ', error);
                  });

                  // ajoute la nouvelle image
                  const newStorageRef = ref(firebaseStorage, barID + '/' + fileInput.name);
                  uploadBytes(newStorageRef, fileInput).then((snapshot) => {
                    getDownloadURL(newStorageRef).then((url) => {
                      // ajoute la boisson dans la base de donnée
                      updateDoc(docRef, {
                        Name: document.getElementById('name').value,
                        Price: document.getElementById('price').value,
                        Description: document.getElementById('description').value,
                        Category: document.getElementById('category').value,
                        ImageRef: url
                      }).then(() => {
                        window.location.reload();
                      });
                      props.onClose();
                    });
                  });
                }
              });
            });
        }
      }
    });
  }

  // charge l'image dans le popup
  function loadPic(e) {
    const image = document.getElementById('preview');
    image.src = URL.createObjectURL(e.target.files[0]);
    image.onload = function () {
      URL.revokeObjectURL(image.src)
    }
  }


  // HTML du popup
  return (
    <div className="container">
      <div className="popup-overlay">
        <div className="popup-content">
          <form >
            <div className="input_box">
              <label  >Nom:</label>
              <input type="text" id="name" defaultValue={props.drink.Name} />
            </div>
            <div className="input_box">
              <label >Descr.:</label>
              <input id="description" className="input_description" cols="40" rows="5" defaultValue={props.drink.Description} />
            </div>
            <div className="input_box">
              <label  >Prix:</label>
              <input type="number" step="any" id="price" defaultValue={props.drink.Price} />
            </div>

            <div className="cat">
              <label className="drink_label" >categorie:</label>
              <select id="category" className="scrolling-menu" defaultValue={props.drink.Category}>
                <option value="Cocktail">Cocktail</option>
                <option value="Biere">Biere</option>
                <option value="Shots">Shots</option>
                <option value="Autres">Autres</option>
              </select>
            </div>
            <div className="input_box">
              <input type="file" id="image" name="image" accept="image/*" onChange={loadPic} />
            </div>

            <div className="upload-img">
              <img className="upload-img" id="preview" src={props.drink.ImageRef} alt="preview" />
            </div>

            <div className="buttons_bottom">
              <button id="closePopup" onClick={props.onClose}>
                Annuler
              </button>
              <button type="submit" onClick={registerDrink}>Valider</button>

            </div>

          </form>

        </div>
      </div>
    </div>
  );
}

export default EditDrink;