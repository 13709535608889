
import './styles/order.css'
import React, { useEffect, useState, useCallback } from "react";
import { firebaseDb } from "../firebase";
import { isAccountAuthorised, getBarmanName } from '../composants/accreditations';
import { collection, query, where, getDocs, Timestamp, deleteDoc } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { updateDoc } from "firebase/firestore";
import ButtonChoice from "../composants/buttons/button_choice";

/* import images */
import logo2 from './images/barashots_logo_2.png'
import menu_icon from './images/menu.png'
import basket_icon from './images/shopping-basket.png'
import list_icon from './images/list.png'
import stat_icon from './images/profits.png'

/**
 * Page représentant une commande
 */
const Order = () => {

  const location = useLocation();
  const url = location.pathname.split('/');
  const barName = url[1];
  const history = useNavigate();

  //get the id of the table from url
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const tableIDParam = urlParams.get('id');
  const tableID = tableIDParam ? parseInt(tableIDParam, 10) : 0;
  var timestamp = null;

  // Convert the time difference to minutes
  const [barmanName, setBarmanName] = useState('');
  const [time, setTime] = useState(0);
  const [statusButton, setStatusButton] = useState('');
  const [, setStatus] = useState('');
  var [totalPrice, setTotalPrice] = useState(0);

  const [currentOrder, setCurrentOrder] = useState({ drinks: {} });
  const [menu, setMenu] = useState([]);


  // Fonction qui permet de récupérer le menu
  const LoadMenu = useCallback(async () => {

    const collectionRef = collection(firebaseDb, 'BaraShots_Bara');
    const q = query(collectionRef, where('name', '==', barName));


    const response = await getDocs(q);
    if (response.empty || response.size > 1) {
      return;
    } else {
      const barID = response.docs[0].id;
      const menuData = [];

      // recherche le menu dans la base de donnée
      getDocs(collection(firebaseDb, "BaraShots_Bara", barID.toString(), "Menu"))
        .then((result) => {
          result.forEach((doc) => {
            menuData.push(doc.data());
          });
          setMenu(menuData);
        })
        .catch((error) => {
          console.error("Error fetching menu data:", error);
        });
    }
  }, [barName]);

  // Fonction qui permet de récupérer les commandes
  const LoadOrders = useCallback(async () => {
    try {
      const collectionRef = collection(firebaseDb, 'BaraShots_Bara');
      const q = query(collectionRef, where('name', '==', barName));

      const response = await getDocs(q);
      if (response.empty || response.size > 1) {
        return [];
      } else {
        const barID = response.docs[0].id;
        const filteredData = await getDocs(collection(firebaseDb, "BaraShots_Bara", barID.toString(), "Orders"),
          where('Statusbar', '!=', 'Done'))

        filteredData.forEach((doc) => {
          // commande qui est pas en etat done et qui a l'id de la table
          var id = doc.data().Table;
          var idToNumber = parseInt(id);
          var status = doc.data().Statusbar;

          // si aucune id est specifiée on prend une commande du barman qui est active
          if (tableID === 0) {
            //search order of the barman if the table is 0
            if (status !== 'Done' && doc.data().Responsable === barmanName) {
              setCurrentOrder(doc.data());

              setTime(Math.floor((new Date() - doc.data().Begin.toDate()) / (1000 * 60)));

              // set the status of the order for button name
              if (doc.data().Statusbar === 'Waiting') {
                setStatusButton('Prise en charge');
                setStatus('En attente');
              }
              else if (doc.data().Statusbar === 'Unpaid') {
                setStatus('En attente de payment')
                setStatusButton('payée');

              }
              else if (doc.data().Statusbar === 'Serving') {
                setStatusButton('livrée');
                setStatus('En cours de préparation');
              }
              else if (doc.data().Statusbar === 'Done') {
                setStatus('en attente de livraison');
                setStatusButton('livrée');
              }
            }

          }
          // si une id est specifiée on prend la commande de la table
          if (idToNumber === tableID && status !== 'Done') {
            setCurrentOrder(doc.data());

            setTime(Math.floor((new Date() - doc.data().Begin.toDate()) / (1000 * 60)));

            // set le status de la commande pour le nom du bouton
            if (doc.data().Statusbar === 'Waiting') {
              setStatusButton('Prise en charge');
              setStatus('En attente');
            }
            else if (doc.data().Statusbar === 'Unpaid') {
              setStatus('En attente de payment')
              setStatusButton('payée');

            }
            else if (doc.data().Statusbar === 'Serving') {
              setStatusButton('livrée');
              setStatus('En cours de préparation');
            }
            else if (doc.data().Statusbar === 'Done') {
              setStatus('en attente de livraison');
              setStatusButton('livrée');
            }

          }
        });

      }
    } catch (error) {
      console.error("Error fetching menu data:", error);
      throw error;
    }
  }, [barName, tableID, barmanName]);

  // Fonction qui retourne le prix d'un item
  function getItemPrice(item) {
    for (var i = 0; i < menu.length; i++) {
      if (menu[i].Name === item) {
        return menu[i].Price;
      }
    }
  }

  // Fonction qui permet de passer à l'étape suivante
  const nextStep = async () => {
    // Prise en charge
    if (currentOrder.Statusbar === 'Waiting') {

      setStatusButton('Payée');
      setStatus('En attente de payment')
      currentOrder.Statusbar = 'Unpaid';

    }
    // Payée
    else if (currentOrder.Statusbar === 'Unpaid') {
      setStatus('En cours de préparation');
      setStatusButton('livrée');
      currentOrder.Statusbar = 'Serving';
    }
    // Livrée
    else if (currentOrder.Statusbar === 'Serving') {
      setStatus('livrée');
      setStatusButton('');
      currentOrder.Statusbar = 'Done';

      // Timestamp de la fin de la commande
      timestamp = Timestamp.now();

    }

    try {
      const collectionRef = collection(firebaseDb, 'BaraShots_Bara');
      const q = query(collectionRef, where('name', '==', barName));

      const response = await getDocs(q);
      if (response.empty || response.size > 1) {
        return [];
      } else {
        const barID = response.docs[0].id;
        const filteredData = await getDocs(collection(firebaseDb, "BaraShots_Bara", barID.toString(), "Orders"),
          where('Statusbar', '!=', 'Done'))

        filteredData.forEach((doc) => {
          // commande qui est pas en etat done et qui a l'id de la table
          var id = doc.data().Table;
          var idToNumber = parseInt(id);
          var status = doc.data().Statusbar;

          if (idToNumber === tableID && status !== 'Done') {
            const orderDoc = doc.ref;
            if (timestamp !== null) {
              // Update the 'Statusbar' field in the order document
              updateDoc(orderDoc, {
                Statusbar: currentOrder.Statusbar || 'Unpaid',
                End: timestamp
              });
            } else if (status === 'Waiting') {
              // Update the 'Statusbar' field in the order document
              updateDoc(orderDoc, {
                Statusbar: currentOrder.Statusbar || 'Unpaid',
                Responsable: barmanName
              });
            } else {
              // Update the 'Statusbar' field in the order document
              updateDoc(orderDoc, {
                Statusbar: currentOrder.Statusbar || 'Unpaid',
              });
            }
          }
        });
      }
    } catch (error) {
      console.error("Error fetching menu data:", error);
      throw error;
    }

    // si une commande est terminée on redirige vers la page des commandes
    if (currentOrder.Statusbar === 'Done') {
      const newUrl = `/${barName}/Barman/orders`;
      history(newUrl);
    }

  }

  // Fonction qui permet de retourner le status de la commande en fonction de l'étape
  function waitingStatus() {
    var status = currentOrder.Statusbar;
    let orderStatus, paymentStatus, deliveryStatus;

    switch (status) {
      case 'Waiting':
        orderStatus = 'order-doing';
        paymentStatus = 'order-waiting';
        deliveryStatus = 'order-waiting';
        break;
      case 'Unpaid':
        orderStatus = 'order-done';
        paymentStatus = 'order-doing';
        deliveryStatus = 'order-waiting';
        break;
      case 'Serving':
        orderStatus = 'order-done';
        paymentStatus = 'order-done';
        deliveryStatus = 'order-doing';
        break;
      default:
        // Default case for unknown status
        orderStatus = 'order-doing';
        paymentStatus = 'order-waiting';
        deliveryStatus = 'order-waiting';
        break;
    }

    return (
      <div className="order-container">
        <div className={`order-status ${orderStatus}`}>En attente</div>
        <div>
          <div className={`order-dotWaiting order-dot`} />
        </div>
        <div className={`order-status ${paymentStatus}`}>Payment</div>
        <div>
          <div className={`order-dotWaiting order-dot`} />
        </div>
        <div className={`order-status ${deliveryStatus}`}>livraison</div>
      </div>
    );
  }

  const deleteOrder = async () => {
    // Delete the order from the database
    try {
      const collectionRef = collection(firebaseDb, 'BaraShots_Bara');
      const q = query(collectionRef, where('name', '==', barName));

      const response = await getDocs(q);
      if (response.empty || response.size > 1) {
        return [];
      } else {
        const barID = response.docs[0].id;
        //TODO le filtre ne marche pas
        const filteredData = await getDocs(collection(firebaseDb, "BaraShots_Bara", barID.toString(), "Orders"),
          where('Statusbar', '!=', 'Done'))

        filteredData.forEach((doc) => {
          var id = doc.data().Table;
          var idToNumber = parseInt(id);
          var status = doc.data().Statusbar;

          if (idToNumber === tableID && status === 'Unpaid') {
            deleteDoc(doc.ref);
          }
        });

      }
    } catch (error) {
      console.error("Error fetching menu data:", error);
      throw error;
    }
    const newUrl = `/${barName}/Barman/orders`;
    history(newUrl);
  }

  useEffect(() => {
    // Vérifie que l'utilisateur est bien connecté et qu'il est autorisé à accéder à la page
    if (!isAccountAuthorised(barName)) {
      // console.warn("User not authorised to access this page, " +
      //     "uncomment the following line to redirect to login page " +
      //     "before commiting on main");
      window.location.href = `/#/login`;
    }

    setBarmanName(getBarmanName());

    setTotalPrice(0);
    LoadMenu();
    LoadOrders();

  }, [LoadMenu, LoadOrders, barName]);


  // HTML de la page
  return (
    <div className="all">
      <header className="header">
        <div className="logo">
          <img src={logo2} alt="BaraShots Logo" />
        </div>
        <div className="title">
          <h1>BaraShots</h1>
          <p>Commandez en toute simplicité</p>
          <p>depuis votre transat</p>
        </div>
      </header>
      <div className="menu-line">
        <div className="menu">Commande détaillé</div>
        <div className="barman">Barman : {barmanName}</div>
      </div>

      <div className="transat-number">
        Table n⁰ {currentOrder.Table}
      </div>



      <div>
        {currentOrder && currentOrder.Drinks && (
          <ul className="order-list">
            {Object.entries(currentOrder.Drinks).map(([drinkName, quantity], index) => {
              if (drinkName !== "End" && quantity > 0) {
                const price = getItemPrice(drinkName);
                totalPrice += price * quantity;
                return (
                  <li className="order-item" key={drinkName + "-" + index}>
                    <div className="order-details">
                      <div className="order-quantity">{quantity}x</div>
                      <div className="order-name">{drinkName}</div>
                    </div>
                    <div className="order-montant">{price}.-</div>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        )}
        {!currentOrder && <div>No order data available.</div>}
      </div>



      <div className="line"></div>

      <div className="order-info">
        <div className="order-primary">
          <div className="order-type">Paiement :</div>
          <div className="order-state">{currentOrder.Payment}</div>
        </div>
        <div className="order-more">{totalPrice}.-</div>
      </div>

      <div className="line"></div>

      <div className="order-info">
        <div className="order-primary">
          <div className="order-type">Statut :</div>

          {waitingStatus()}

        </div>
      </div>

      <div className="line"></div>

      <div className="order-info">
        <div className="order-primary">
          <div className="order-type">Temps :</div>
          <div className="order-state">
            {time.toString()} min
          </div>
        </div>
      </div>

      <div>
        {currentOrder.Statusbar === "Unpaid" ? (
          <div className="order-button-container two-buttons">
            <button className="order-button" onClick={deleteOrder}>Annuler</button>
            <button className="order-button" onClick={nextStep}>
              {statusButton}
            </button>
          </div>
        ) : (
          <div className="order-button-container">
            <button className="order-button" onClick={nextStep}>
              {statusButton}
            </button>
          </div>
        )}
      </div>

      <div className="footer">
        <ButtonChoice button_image={list_icon} button_type={'orders'} />
        <div className="vl"></div>
        <ButtonChoice button_image={basket_icon} button_type={'order'} />
        <div className="vl"></div>
        <ButtonChoice button_image={menu_icon} button_type={'menu'} />
        <div className="vl"></div>
        <ButtonChoice button_image={stat_icon} button_type={'stats'} />
      </div>
    </div >
  );
};

export default Order;