import React from "react";
import { firebaseAuth } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";

/**
 * Page pour l'inscription
 */
const SignupPage = () => {
  const onClick = () => {
    console.log('Register button clicked');

    const email = document.getElementById('email').value;
    const password = document.getElementById('psw').value;

    createUserWithEmailAndPassword(firebaseAuth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        // Do something with the user after registration
        alert("User registered successfully:\n" + user.email + "\n" + user.uid);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        alert("Error:\n " + errorMessage + "\n" + errorCode);
      });
  }


  // HTML du formulaire d'inscription
  return (
    <div>
      <form>
        <div>
          <h1>Sing Up</h1>
          <p>Remplissez ce formulaire pour vous inscrire.</p>
          <label for="email"><b>Email</b></label>
          <input type="text" placeholder="Enter Email" name="email" id="email" required />
          <br />
          <label><b>Password</b></label>
          <input type="password" placeholder="Password" name="psw" id="psw" required />
          <p><button type="button" onClick={onClick}>Enregistrement</button></p>
        </div>
      </form>
    </div>
  );
};

export default SignupPage;