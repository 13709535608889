import './styles/edit_menu.css'
import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { firebaseDb } from '../firebase';
import { isAccountAuthorised, getBarmanName } from '../composants/accreditations';
import { collection, query, where, getDocs } from 'firebase/firestore';

/* import images */
import logo2 from './images/barashots_logo_2.png'
import menu_icon from './images/menu.png'
import basket from './images/shopping-basket.png'
import list_icon from './images/list.png'
import stat from './images/profits.png'
import ButtonChoice from "../composants/buttons/button_choice.js";
import ButtonAddDrink from '../composants/buttons/button_add_drink'
import ButtonEditDrink from '../composants/buttons/button_edit_drink'
import ButtonDeleteDrink from '../composants/buttons/button_delete_drink'

/**
 * La page edit_menu permet de gérer le menu du bar
 */
const EditMenu = () => {
  // Variables pour la redirection et les query dans Firebase
  const location = useLocation();
  const url = location.pathname.split('/');
  const barName = url[1];

  // Etats react
  const [barmanName, setBarmanName] = useState('');
  const [menu, setMenu] = useState([]); // Etat du menu
  const [isLoading, setIsLoading] = useState(true); // Etat du chargemen

  // Groupe les boissons par catégorie
  function groupDrinks(menu) {
    const tmp = {};
    menu.forEach((drink) => {
      if (!tmp[drink.Category]) {
        tmp[drink.Category] = [];
      }
      tmp[drink.Category].push(drink);
    });
    return tmp;
  }

  // Liste des boissons à récupérer depuis la base de données
  const LoadMenu = useCallback(async () => {
    // On récupère toutes les boissons du bar
    const collectionRef = collection(firebaseDb, 'BaraShots_Bara');
    const q = query(collectionRef, where('name', '==', barName));
    const response = await getDocs(q);
    if (response.empty || response.size > 1) {
      setIsLoading(false);
      return;
    } else {
      // Sauve les boissons dans un tableau
      const barID = response.docs[0].id;
      const menuData = [];
      getDocs(collection(firebaseDb, "BaraShots_Bara", barID.toString(), "Menu"))
        .then((result) => {
          result.forEach((doc) => {
            menuData.push(doc.data());
          });
          // Set les états
          setMenu(groupDrinks(menuData));
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching menu data:", error);
          setIsLoading(false);
        });
    }
  }, [barName]);

  // Charge le menu au chargement de la page
  useEffect(() => {
    // Vérifie que l'utilisateur est bien connecté et qu'il est autorisé à accéder à la page
    if (!isAccountAuthorised(barName)) {
      // console.warn("User not authorised to access this page, " +
      //     "uncomment the following line to redirect to login page " +
      //     "before commiting on main");
      window.location.href = `/#/login`;
    }

    setBarmanName(getBarmanName());
    LoadMenu();
  }, [LoadMenu, barName]);

  // HTML du menu
  return (
    <div className="all">
      <header className="header">
        <div className="logo">
          <img src={logo2} alt="BaraShots Logo" />
        </div>
        <div className="title">
          <h1>BaraShots</h1>
          <p>Commandez en toute simplicité</p>
          <p>depuis votre transat</p>
        </div>
      </header>
      <div className="menu-line">
        <div className="menu">Menu</div>
        <div className="transat">Barman : {barmanName}</div>
      </div>

      <ButtonAddDrink />

      <div className="footer">

        <ButtonChoice button_image={list_icon} button_type={'orders'} />

        <div className="vl"></div>

        <ButtonChoice button_image={basket} button_type={'order'} />

        <div className="vl"></div>

        <ButtonChoice button_image={menu_icon} button_type={'menu'} />

        <div className="vl"></div>

        <ButtonChoice button_image={stat} button_type={'stats'} />
      </div>
      {
        isLoading ? ( /* Si le chargement est en cours */
          <p>Loading...</p>
        ) : ( /* Si le chargement est terminé */
          Object.keys(menu).map((Category) => (
            <div key={Category}>
              <div className="section-title">
                <div className="menu-title">{Category}</div>
                <div className="line"></div>
              </div>
              {menu[Category].map((drink) => (
                <div key={drink.Name} className="cocktail-specs">
                  <div className="cocktail-image">
                    <img src={drink.ImageRef} alt="Cocktail" />
                  </div>
                  <div className="cocktail-price">
                  </div>
                  <div className="cocktail-details">
                    <div className="cocktail-name">{drink.Name}</div>
                    <div className="ingredients">{drink.Description}</div>
                  </div>
                  <ButtonEditDrink drink={drink} />
                  <ButtonDeleteDrink drink={drink} />
                </div>
              ))}
            </div>
          ))
        )
      }
    </div>
  );
};

export default EditMenu;
