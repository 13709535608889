import React, { useState } from 'react';
import EditDrink from '../popups/edit_drink';

/* import images */
import pen from '../../pages/images/pen.png'

/**
* Ce bouton est utilisé pour modifier une boisson du panier
* props : drink
* drink : la boisson à modifier
*/
const ButtonEditDrink = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  // HTML du bouton
  return (
    <div className="edit">
      <img src={pen} alt={"Modification d'une boisson"} onClick={openPopup} />
      {isOpen && (<EditDrink drink={props.drink} onClose={closePopup} />)}
    </div>
  );
};

export default ButtonEditDrink;

