import React, { useState } from 'react';
import PopupStatus from '../popups/status.js';

/* import images */
import time_left from '../../pages/images/time-left.png';

/**
* Ce bouton est utilisé pour ouvrir la popup du statut de la commande
*
* status : le statut de la commande
*/
const ButtonStatus = ({ status }) => {
  // Permet de savoir si la popup est ouverte ou non
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Ouvre et ferme le popup
  const openPopup = () => {
    setIsPopupOpen(true);
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };

  // HTML du bouton
  return (
    <div className="new-time-left">
      <img src={time_left} onClick={openPopup} alt="BaraShots Logo" />
      {isPopupOpen && (<PopupStatus status={status} onClose={closePopup} />)}
    </div>
  );
};

export default ButtonStatus;
