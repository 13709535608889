import React from "react";
import { Link } from "react-router-dom";
import './styles/landing_page.css'

/* import images */
import hero from './images/hero.png'
import hand from './images/hand.png'
import waiters from './images/waiters.png'
import review from './images/review.png'
import logo2 from './images/barashots_logo_2.png'
import young_man from './images/young_man.png'
import pp_ian from './images/pp_ian.png'
import pp_thomas from './images/pp_thomas.png'
import pp_mel from './images/pp_mel.png'
import pp_costi from './images/pp_costi.png'
import menu_client from './images/menu_client.jpg'
import menu_barman from './images/menu_barman.jpg'
import stats from './images/stats.jpg'
import orders from './images/orders.jpg'
import order from './images/order.jpg'
import statut from './images/statut.jpg'
import cart from './images/cart.jpg'

/**
 * La page landing_page est la page d'accueil du site
 */
const LandingPage = () => {


  // HTML de la landing page
  return (
    <div id="gradient">
      <nav>
        <div className="logo">
          <img src={logo2} alt="Software Icon" />
          <span>BaraShots</span>
        </div>
        <Link to="/login">
          <button className="login-button" >Login</button>
        </Link>
      </nav>

      <div className="pt-24">
        <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">

          <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">

            <h1 className="my-4 text-5xl font-bold leading-tight need-white">
              BaraShots
            </h1>
            <p className="leading-normal text-2xl mb-8 need-white">
              Surprenez vos clients : Service de plage innovant avec BaraShots !
            </p>

          </div>

          <div className="w-full md:w-3/5 py-6 text-center">
            <img src={hero} alt="hero" />
          </div>
        </div>
      </div>
      <div className="relative -mt-12 lg:-mt-24">
        <svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fillRule="nonzero">
              <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
              <path
                d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                opacity="0.100000001"
              ></path>
              <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" id="Path-4" opacity="0.200000003"></path>
            </g>
            <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fillRule="nonzero">
              <path
                d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"
              ></path>
            </g>
          </g>
        </svg>
      </div>
      <section className="bg-white border-b py-8">
        <div className="container max-w-5xl mx-auto m-8">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            BaraShots
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-5/6 sm:w-1/2 p-6">
              <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                Plaisir sans effort à la plage
              </h3>
              <p className="text-gray-600 mb-8">
                Offrez à vos clients une expérience sans pareille en leur permettant de commander des boissons depuis leur transat grâce à BaraShots. Fini les files d'attente et les tracas, place à une détente totale. L'avenir de la commande de plage est ici. Grâce à notre technologie de code QR innovante, vos clients peuvent passer leur commande en un instant. Il suffit d'un scan pour découvrir la magie de la facilité.
                <br />
                <br />

              </p>
            </div>
            <div className="w-full sm:w-1/2 p-6">
              <img src={young_man} alt="young_man" />

            </div>
          </div>
          <div className="flex flex-wrap flex-col-reverse sm:flex-row">
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <img src={hand} alt="hand" />
            </div>
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <div className="align-middle">
                <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                  Code QR, Votre allié unique
                </h3>
                <p className="text-gray-600 mb-8">
                  Le code QR redirige les clients directement sur votre menu, et leur donne la possibilité de commander vos boissons, de sélectionner le moyen de paiement et de suivre l'état de la commande rapidement, facilement, et de manière intuitive.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-5/6 sm:w-1/2 p-6">
              <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                Nouvelles possibilités de service
              </h3>
              <p className="text-gray-600 mb-8">
                Fournissez à vos serveurs un service simple et rapide pour gérer les commandes. Les serveurs peuvent suivre les commandes en temps réel, qu'elles soient en attente ou attribuée à vos collègues. Gérez votre commande en cours, de manière simple et centralisée. Gérez votre menu et vos boissons, et ajoutez-en de nouveaux en quelques clics. Accédez à des statistiques détaillées sur vos ventes et vos revenus.
                <br />
                <br />

              </p>
            </div>
            <div className="w-full sm:w-1/2 p-6">
              <img src={waiters} alt="waiters" />
            </div>
          </div>
          <div className="flex flex-wrap flex-col-reverse sm:flex-row">
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <img src={review} alt="review" />
            </div>
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <div className="align-middle">
                <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                  Un bar de plage de classe mondiale
                </h3>
                <p className="text-gray-600 mb-8">
                  Distinction et succès vous attendent lorsque vous intégrez BaraShots dans votre offre. Votre bar de plage sera l'endroit incontournable, grâce à notre solution basée sur le code QR qui réinvente l'expérience de plage.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="titlesss">Visuels</div>
        <h3 className="text-2xl text-gray-800 center leading-none mb-3">
           Un design épuré et moderne pour une expérience utilisateur optimale
        </h3>

        <div className="mockup-image-container">
          <div className="image-box">
            <h3>Menu des boissons pour les clients</h3>
            <img src={menu_client} alt="figma boisson" />
          </div>
          <div className="image-box">
            <h3>Progression de la commande pour le client</h3>
            <img src={statut} alt="figma progression" />
          </div>
          <div className="image-box">
            <h3>Panier du client</h3>
            <img src={cart} alt="figma progression" />
          </div>
        </div>

        <div className="mockup-image-container">
            <div className="image-box">
              <h3>Liste des commandes</h3>
              <img src={orders} alt="figma commande" />
            </div>
            <div className="image-box">
              <h3>Commande en cours pour les barmans</h3>
              <img src={order} alt="figma en cours" />
            </div>
            <div className="image-box">
              <h3>Modification d'une boisson</h3>
              <img src={menu_barman} alt="figma en cours" />
            </div>
            <div className="image-box">
              <h3>Statistiques des barmans</h3>
              <img src={stats} alt="figma en cours" />
            </div>
        </div>

        <div className="titlesss">Le sang de la veine !</div>
        <h3 className="text-2xl text-gray-800 center leading-none mb-3">
            L'équipe BaraShots
        </h3>
        <div className="team">
          <div className="team-member">
            <img src={pp_costi} alt="Team Member 1" />
            <h2>Costi</h2>
            <p>B-52</p>
            <p>"B-52 le shot, pas le bombardier. Mais vous savez tout le monde se trompe."</p>
          </div>

          <div className="team-member">
            <img src={pp_ian} alt="Team Member 2" />
            <h2>Ianneuh Escher</h2>
            <p>Berliner Luft</p>
            <p>"On m'appelle Xavier dans le millieu."</p>
          </div>

          <div className="team-member">
            <img src={pp_thomas} alt="Team Member 3" />
            <h2>Thomas Germano</h2>
            <p>Washing Machine</p>
            <p>"Au moins, j'étais pas le premier à vomir"</p>
          </div>

          <div className="team-member">
            <img src={pp_mel} alt="Team Member 4" />
            <h2>Mélissa Gehring</h2>
            <p>Mojito de trop</p>
            <p>"J'ai bu du feu. Aussi, j'avoue c'était moi la première."</p>
          </div>
        </div>
      </section>

      <svg className="wave-top" viewBox="0 0 1439 147" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1.000000, -14.000000)" fillRule="nonzero">
            <g className="wave" fill="#f8fafc">
              <path
                d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"
              ></path>
            </g>
            <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
              <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
                <path
                  d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                  opacity="0.100000001"
                ></path>
                <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" opacity="0.200000003"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>

      <section className="container mx-auto text-center py-6 mb-12">
        <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
          Nous contacter !
        </h2>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
        </div>
        <h3 className="my-4 text-3xl leading-tight need-white">
          Écrivez nous pour adhérer à notre service !
        </h3>
        <Link to="/contact">
        <button className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
          Contacter !
        </button>
        </Link>
      </section>


      <footer>

      </footer>

    </div>
  );
};

export default LandingPage;