import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyBdCjJ5M2stvJpUisBMYjQsHbJyHhQPJ4Y",
  authDomain: "barashots-d569f.firebaseapp.com",
  projectId: "barashots-d569f",
  storageBucket: "barashots-d569f.appspot.com",
  messagingSenderId: "901730315917",
  appId: "1:901730315917:web:d8ab593230c2abc6afbcc4"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

export {
  app as firebaseApp,
  auth as firebaseAuth,
  db as firebaseDb,
  storage as firebaseStorage,
  functions as firebaseFunctions
};