import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

/* import images */
import menu from '../../pages/images/menu.png'

/**
* Ce bouton est utilisé pour ouvrir la page du manu pour le client
*
* props : cart, id, underlined
* cart : contient les boissons dans le panier
* id : l'id du transat
* underlined : le bouton est souligné si c'est celui de la page du panier
*/
function ButtonMenu(props) {
  // Paramètres pour la redirection
  const location = useLocation();
  const history = useNavigate();

  // Appelé quand on clique sur le bouton
  const onClick = () => {
    // Stocke le panier dans le local storage
    var string = JSON.stringify(props.cart);
    localStorage.setItem("cart", string);

    // Redirige vers la page du panier
    const url = location.pathname.split("/");
    const newUrl = `/${url[1]}/${url[2]}/menu?id=${props.id}`;
    history(newUrl);
  }

  // HTML du bouton
  return (
    <div className={props.underlined ? "client-underlined client-footer-image" : "client-footer-image"} onClick={onClick}>
      <img src={menu} alt="BaraShots Logo" />
    </div>
  );
}

export default ButtonMenu;