import React, { useEffect, useState, useCallback } from "react";
import './styles/orders.css'
import ButtonChoice from "../composants/buttons/button_choice";
import { firebaseDb } from "../firebase";
import { isAccountAuthorised, getBarmanName } from '../composants/accreditations';
import { collection, query, where, getDocs } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";

/* import images */
import logo2 from './images/barashots_logo_2.png'
import hourglass from './images/hourglass.png'
import menu_icon from './images/menu.png'
import basket_icon from './images/shopping-basket.png'
import list_icon from './images/list.png'
import stat_icon from './images/profits.png'

/**
 * Page représentant les commandes du bar
 */
const Orders = () => {

  const refreshInterval = 60000; // Refresh toutes les 60 seconds

  const location = useLocation();
  const url = location.pathname.split('/');
  const barName = url[1];
  const history = useNavigate();

  const [barmanName, setBarmanName] = useState('');
  const [orders, setOrders] = useState([]);

  // Récupère les commandes de la base de données
  const LoadOrders = useCallback(async () => {
    try {
      const collectionRef = collection(firebaseDb, 'BaraShots_Bara');
      const q = query(collectionRef, where('name', '==', barName));
      const orderData = [];


      const response = await getDocs(q);
      if (response.empty || response.size > 1) {
        return [];
      } else {
        const barID = response.docs[0].id;
        const ordersRef = collection(firebaseDb, "BaraShots_Bara", barID.toString(), "Orders");

        // recupere les commandes de la base de donnée
        const ordersSnapshot = await getDocs(ordersRef);

        ordersSnapshot.forEach((doc) => {
          orderData.push(doc.data());
        });
      }
      setOrders(groupOrders(orderData));
    } catch (error) {
      console.error("Error fetching menu data:", error);
      throw error;
    }
  }, [barName]);

  function groupOrders(orders_list) {
    const tmp = {};

    orders_list.forEach((order) => {
      if (!tmp[order.Statusbar]) {
        tmp[order.Statusbar] = [];
      }
      tmp[order.Statusbar].push(order);
    });
    return tmp;
  }

  // Ouvre la page de la commande dont l'id est passé en paramètre
  function openPendingOrder(id) {
    const newUrl = `/${barName}/Barman/order?id=${id}`;
    history(newUrl);
  }

  // Calcule la différence de temps entre l'heure actuelle et l'heure de début de la commande
  const calculateTimeDifference = (beginTime) => {
    const currentTime = new Date();
    const orderBeginTime = beginTime.toDate();
    const timeDifference = Math.floor((currentTime - orderBeginTime) / (1000 * 60)); // Converti en minutes
    return timeDifference;
  };


  useEffect(() => {
    // Vérifie que l'utilisateur est bien connecté et qu'il est autorisé à accéder à la page
    if (!isAccountAuthorised(barName)) {
      // console.warn("User not authorised to access this page, " +
      //     "uncomment the following line to redirect to login page " +
      //     "before commiting on main");
      window.location.href = `/#/login`;
    }

    setBarmanName(getBarmanName());

    LoadOrders();
    const refreshOrdersInterval = setInterval(() => {
      LoadOrders();
    }, refreshInterval);

    return () => clearInterval(refreshOrdersInterval);

  }, [LoadOrders, barName]);

  // HTML de la page
  return (
    <div className="all">
      <header className="header">
        <div className="logo">
          <img src={logo2} alt="BaraShots Logo" />
        </div>
        <div className="title">
          <h1>BaraShots</h1>
          <p>Commandez en toute simplicité</p>
          <p>depuis votre transat</p>
        </div>
      </header>
      <div className="menu-line">
        <div className="menu">Commandes</div>
        <div className="barman">Barman : {barmanName}</div>
      </div>

      <div className="section-title">
        <div className="menu-title">En attente</div>
        <div className="line"></div>
      </div>

      {
        Object.keys(orders).map((status) => {
          const orderList = orders[status];

          if (status === "Waiting") {
            return orderList.map((order, index) => {
              // Create a new object with only the Drinks property
              const drinks = order.Drinks;


              return <div className="order-specs" key={order.Table + "-" + index} onClick={() => openPendingOrder(order.Table)}>
                <div className="order-details-progress">
                  <div className="order-number">Table n⁰ {order.Table}</div>
                  <div className="order-drinks">
                    {Object.entries(drinks).map(([drinkName, quantity], drinkIndex) => {
                      return (
                        <span key={drinkName + "-" + drinkIndex}>
                          {quantity}x {drinkName},{' '}
                        </span>
                      );
                    })}
                  </div>
                </div>
                <div className="order-payment">
                  <div className="order-price">Statut</div>
                  <div className="order-payment-method">{order.Statusbar}</div>
                </div>
                <div className="order-time">
                  <div className="hourglass">
                    <img src={hourglass} alt="hourglass Logo" />
                  </div>
                  <div className="time">{
                    //TODO calculate time left
                    calculateTimeDifference(order.Begin) + " min"
                  }</div>
                </div>
              </div>
            });
          }

          return null;
        })
      }

      <div className="section-title">
        <div className="menu-title">En cours</div>
        <div className="line"></div>
      </div>

      {
        Object.keys(orders).map((status) => {
          const orderList = orders[status];

          if (status === "Unpaid" || status === "Serving") {
            return orderList.map((order, index) => {
              // Create a new object with only the Drinks property
              const drinks = order.Drinks;
              return <div className={`order-specs ${order.Responsable === barmanName ? 'resp' : ''}`} key={order.Table + "-" + index} onClick={() => openPendingOrder(order.Table)}>
                <div className="order-details-progress">
                  <div className="order-number">Table n⁰ {order.Table}</div>
                  <div className="order-drinks">
                    {Object.entries(drinks).map(([drinkName, quantity], drinkIndex) => {
                      return (
                        <span key={drinkName + "-" + drinkIndex}>
                          {quantity}x {drinkName},{' '}
                        </span>
                      );
                    })}
                  </div>
                </div>
                <div className="order-payment">
                  <div className="order-price">Statut</div>
                  <div className="order-payment-method">{order.Statusbar}</div>
                </div>
                <div className="order-payment">
                  <div className="order-price">Resp.</div>
                  <div className="order-payment-method">{order.Responsable}</div>
                </div>
              </div>
            });
          }
          return null;
        })
      }


      <div className="footer">
        <ButtonChoice button_image={list_icon} button_type={'orders'} />
        <div className="vl"></div>

        <ButtonChoice button_image={basket_icon} button_type={'order'} />
        <div className="vl"></div>
        <ButtonChoice button_image={menu_icon} button_type={'menu'} />
        <div className="vl"></div>
        <ButtonChoice button_image={stat_icon} button_type={'stats'} />
      </div>
    </div>
  );
};

export default Orders;