import './styles/stats_page.css'
import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { firebaseDb } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { isAccountAuthorised, getBarmanName } from '../composants/accreditations';
import ButtonChoice from "../composants/buttons/button_choice";

/* import images */
import logo2 from './images/barashots_logo_2.png'
import menu_icon from './images/menu.png'
import basket_icon from './images/shopping-basket.png'
import list_icon from './images/list.png'
import stat_icon from './images/profits.png'

/**
 * Page représentant les statistiques du barman et du bar
 */
const Statistics = () => {
  // Variables pour la redirection et les query dans Firebase
  const location = useLocation();
  const url = location.pathname.split('/');
  const barName = url[1];

  const [barmanName, setBarmanName] = useState('');
  const [statsPerso, setStatsPerso] = useState({});
  const [statsGlobal, setStatsGlobal] = useState({});

  // Récupère les commandes de la base de données
  const LoadOrders = useCallback(async () => {
    try {
      // On récupère toutes les commandes du bar
      const collectionRef = collection(firebaseDb, 'BaraShots_Bara');
      const q = query(collectionRef, where('name', '==', barName));
      const response = await getDocs(q);
      if (response.empty || response.size > 1) {
        return [];
      } else {
        // Sauve les commandes dans un tableau
        const barID = response.docs[0].id;
        const ordersData = await getDocs(collection(firebaseDb, "BaraShots_Bara", barID.toString(), "Orders"))
          .then((result) => {
            const ordersData = [];
            result.forEach((doc) => {
              if (doc.data().Statusbar === "Done") {
                ordersData.push(doc.data());
              }
            });
            return ordersData;
          });
        return ordersData;
      }
    } catch (error) {
      console.error("Error fetching orders data:", error);
      throw error;
    }
  }, [barName]);


  // calcule les statistiques personnelles du barman
  function computeStatsPerso(orders) {
    setStatsPerso((prevStats) => ({
      ...prevStats,
      nbOrders: orders.length,
      totalMoney: orders.reduce((a, b) => a + b.Total, 0),
      avgTime: orders.reduce((a, b) => a + (b.End - b.Begin), 0) / (orders.length * 60),
    }));
  }

  // calcule les statistiques globales du bar
  function computeStatsGlobal(orders) {
    // Calcule le nombre de chaque boisson commandée au total
    var groupDrinks = {};
    orders.forEach((order) => {
      for (const [drink, count] of Object.entries(order.Drinks)) {
        if (!groupDrinks[drink]) {
          groupDrinks[drink] = 0;
        }
        groupDrinks[drink] += count;
      }
    });

    setStatsGlobal((prevStats) => ({
      ...prevStats,
      nbOrders: orders.length,
      totalMoney: orders.reduce((a, b) => a + b.Total, 0),
      avgTime: orders.reduce((a, b) => a + (b.End - b.Begin), 0) / (orders.length * 60),
      mostOrderedDrink: Object.keys(groupDrinks).reduce((a, b) => groupDrinks[a] > groupDrinks[b] ? a : b),
    }));
  }

  useEffect(() => {
    // Vérifie que l'utilisateur est bien connecté et qu'il est autorisé à accéder à la page
    if (!isAccountAuthorised(barName)) {
      // console.warn("User not authorised to access this page, " +
      //     "uncomment the following line to redirect to login page " +
      //     "before commiting on main");
      window.location.href = `/#/login`;
    }

    setBarmanName(getBarmanName());

    // Récupère toutes les commandes
    var orders = LoadOrders(); // Appel de la fonction quand le composant est monté
    orders.then((result) => {
      computeStatsGlobal(result);
      const resultBarman = result.filter((order) => order.Responsable === barmanName);
      computeStatsPerso(resultBarman);
    });
  }, [LoadOrders, barmanName, barName]);


  // HTML de la page
  return (
    <div className="all">
      <header className="header">
        <div className="logo">
          <img src={logo2} alt="BaraShots Logo" />
        </div>
        <div className="title">
          <h1>BaraShots</h1>
          <p>Commandez en toute simplicité</p>
          <p>depuis votre transat</p>
        </div>
      </header>
      <div className="menu-line">
        <div className="menu">Statistiques</div>
        <div className="barman">Barman : {barmanName}</div>
      </div>

      <div className="section-title">
        <div className="menu-title">Personnelles</div>
        <div className="line"></div>
      </div>

      <div className="stat-info">
        <div className="stat-primary">
          <div className="stat-type">Nombre de commandes :</div>
        </div>
        <div className="stat-more">{statsPerso.nbOrders || 0}</div>
      </div>

      <div className="stat-info">
        <div className="stat-primary">
          <div className="stat-type">Argent ammassé :</div>
        </div>
        <div className="stat-more">{statsPerso.totalMoney || 0}.-</div>
      </div>

      <div className="stat-info">
        <div className="stat-primary">
          <div className="stat-type">Temps d'attente moyen :</div>
        </div>
        <div className="stat-more">{Math.floor(statsPerso.avgTime || 0)} min {Math.round((statsPerso.avgTime - Math.floor(statsPerso.avgTime || 0)) * 60)} sec</div>
      </div>

      <div className="section-title">
        <div className="menu-title">Globales</div>
        <div className="line"></div>
      </div>

      <div className="stat-info">
        <div className="stat-primary">
          <div className="stat-type">Nombre de commandes :</div>
        </div>
        <div className="stat-more">{statsGlobal.nbOrders || 0}</div>
      </div>

      <div className="stat-info">
        <div className="stat-primary">
          <div className="stat-type">Argent ammassé :</div>
        </div>
        <div className="stat-more">{statsGlobal.totalMoney || 0}.-</div>
      </div>

      <div className="stat-info">
        <div className="stat-primary">
          <div className="stat-type">Temps d'attente moyen :</div>
        </div>
        <div className="stat-more">{Math.floor(statsGlobal.avgTime || 0)} min {Math.round((statsGlobal.avgTime - Math.floor(statsGlobal.avgTime || 0)) * 60)} sec</div>
      </div>

      <div className="stat-info">
        <div className="stat-primary">
          <div className="stat-type">Boisson la plus commandée :</div>
        </div>
        <div className="stat-more">{statsGlobal.mostOrderedDrink}</div>
      </div>


      <div className="footer">
        <ButtonChoice button_image={list_icon} button_type={'orders'} />
        <div className="vl"></div>
        <ButtonChoice button_image={basket_icon} button_type={'order'} />
        <div className="vl"></div>
        <ButtonChoice button_image={menu_icon} button_type={'menu'} />
        <div className="vl"></div>
        <ButtonChoice button_image={stat_icon} button_type={'stats'} />
      </div>
    </div>
  );
};

export default Statistics;