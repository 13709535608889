import React, { useState } from 'react';
import AddDrink from '../popups/add_drink';

/* import images */
import cocktail_logo from '../../pages/images/cocktail.png';

/**
* Ce bouton est utilisé pour ajouter une boisson au panier
*/
const ButtonAddDrink = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  // HTML du bouton
  return (
    <div className="add-drink">
      <img src={cocktail_logo} alt={"Ajout d'une boisson"} onClick={openPopup} />
      {isOpen && (<AddDrink onClose={closePopup} />)}
    </div>
  );
};

export default ButtonAddDrink;

