import React from 'react';
import './styles/status.css';

//Cette fonction permet de définir le statut d'une commande pour l'affichage dans le popup.
function waitingStatus(status) {
  let orderStatus, paymentStatus, deliveryStatus, dotOrderStatus, dotPaymentStatus;

  switch (status) {
    case 'Waiting':
      orderStatus = 'client-order-doing';
      dotOrderStatus = 'client-order-dotDoing';
      paymentStatus = 'client-order-waiting';
      dotPaymentStatus = 'client-order-dotWaiting';
      deliveryStatus = 'client-order-waiting';

      break;
    case 'Unpaid':
      orderStatus = 'client-order-done';
      dotOrderStatus = 'client-order-dotDone';
      paymentStatus = 'client-order-doing';
      dotPaymentStatus = 'client-order-dotDoing';
      deliveryStatus = 'client-order-waiting';
      break;
    case 'Serving':
      orderStatus = 'client-order-done';
      dotOrderStatus = 'client-order-dotDone';
      paymentStatus = 'client-order-done';
      dotPaymentStatus = 'client-order-dotDone';
      deliveryStatus = 'client-order-doing';
      break;
    default:
      orderStatus = 'client-order-doing';
      dotOrderStatus = 'client-order-dotDoing';
      paymentStatus = 'client-order-waiting';
      dotPaymentStatus = 'client-order-dotWaiting';
      deliveryStatus = 'client-order-waiting';
      break;
  }


  return (
    <div className="client-order-container">
      <div className={`client-order-status ${orderStatus}`}>En attente</div>
      <div>
        <div className={`${dotOrderStatus} client-order-dot`} />
        <div className={`${dotOrderStatus} client-order-dot`} />
        <div className={`${dotOrderStatus} client-order-dot`} />
      </div>
      <div className={`client-order-status ${paymentStatus}`}>Paiement</div>
      <div>
        <div className={`${dotPaymentStatus} client-order-dot`} />
        <div className={`${dotPaymentStatus} client-order-dot`} />
        <div className={`${dotPaymentStatus} client-order-dot`} />
      </div>
      <div className={`client-order-status ${deliveryStatus}`}>Livraison</div>
    </div>
  );
}





/**
* Ce popup permet d'afficher le statut d'une commande d'un client
* 3 états de livraison, "Waiting", "Unpaid", "Serving"
*
* props : status, onClose
* status : cart, id, underlined
* onClose : contient les boissons dans le panier
*/
const PopupStatus = ({ status, onClose }) => {

  // HTML du popup
  return (
    <div className="client-order-popup-overlay">
      <div className="client-order-popup-content">
        <button className="client-order-close-button" onClick={onClose}>X</button>
        {waitingStatus(status)}
      </div>
    </div>
  );
};


export default PopupStatus;
