import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

/**
* Ce bouton est utilisé pour ouvrir la page du manu pour le client
*
* props : cart, id, underlined
* cart : contient les boissons dans le panier
* id : l'id du transat
* underlined : le bouton est souligné si c'est celui de la page du panier
*/
function ButtonChoice(props) {
  // Paramètres pour la redirection
  const location = useLocation();
  const history = useNavigate();

  var button_type = props.button_type;


  // Appelé quand on clique sur le bouton
  const onClick = () => {

    // Redirige vers la page du panier
    const url = location.pathname.split("/");
    const newUrl = `/${url[1]}/${url[2]}/${button_type}`;

    history(newUrl);

  }

  const url = location.pathname.split("/");
  var page_type = url[3];

  // HTML du bouton
  return (
    <div className={props.button_type === page_type ? "underlined footer-image" : "footer-image"} onClick={onClick}>
      <img src={props.button_image} alt="BaraShots Logo" />
    </div>
  );
}

export default ButtonChoice;