import React from "react";
import "./styles/delete_drink.css";
import { firebaseDb, firebaseStorage } from "../../firebase";
import { collection, query, where, getDocs, deleteDoc } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { deleteObject, ref } from "firebase/storage";

/**
 * Ce popup est utilisé pour supprimer une boisson du menu
 *  
 * props : onClose
 * onClose : fonction pour fermer le popup
 */
const DeleteDrink = (props) => {
  const location = useLocation();

  // separe l'url pour recuperer le nom du bar
  const url = location.pathname.split("/");
  const barName = url[1];


  const collectionRef = collection(firebaseDb, "BaraShots_Bara");
  // Get generated ID for the document from the name of the bar
  const q = query(collectionRef, where("name", "==", barName));
  var barID = "";
  getDocs(q).then((response) => {
    if (response.empty) {
      console.log('No bar found from the URL.');
      return;
    } else {
      if (response.size > 1) {
        console.log('More than one bar found from the URL.');
        return;
      } else {
        barID = response.docs[0].id;
        getDocs(collection(firebaseDb, "BaraShots_Bara", barID.toString(), "Menu"))
          .then((result) => {
            result.forEach((doc) => {
              // Get the current document ref
              if (props.drink.Name === doc.data().Name) {
                console.log("deleting drink " + doc.data().Name + "from menu");
                const prevRef = doc.data().ImageRef;
                const storageRef = ref(firebaseStorage, prevRef);
                deleteObject(storageRef).then(() => {
                  console.log('Deleted the previous image!');
                }).catch((error) => {
                  console.log('Error while deleting the previous image: ', error);
                });
                deleteDoc(doc.ref).then(() => {
                  window.location.reload();
                }).catch((error) => {
                  console.log('Error while deleting the drink: ', error);
                });
                props.onClose();

              }
            });
          });
      }
    }
  });


  const handleDelete = (event) => {

    const collectionRef = collection(firebaseDb, "BaraShots_Bara");
    // recherche le bar dans la base de donnée
    const q = query(collectionRef, where("name", "==", barName));
    var barID = "";
    getDocs(q).then((response) => {
      if (response.empty) {
        console.log('No bar found from the URL.');
        return;
      } else {
        if (response.size > 1) {
          console.log('More than one bar found from the URL.');
          return;
        } else {
          barID = response.docs[0].id;
          getDocs(collection(firebaseDb, "BaraShots_Bara", barID.toString(), "Menu"))
            .then((result) => {
              result.forEach((doc) => {
                // recherche la boisson dans la base de donnée
                if (props.drink.Name === doc.data().Name) {
                  deleteDoc(doc.ref).then(() => {
                    window.location.reload();
                  });
                  props.onClose();
                }
              });
            });
        }
      }
    });
  }

  // HTML du popup
  return (
    <div className="delete-drink-popup-overlay">
      <div className="delete-drink-popup-content">
        <button className="delete-drink-close-button" onClick={props.onClose}>X</button>
        <div className="delete-drink-popup-title">Confirmer la suppression de la boisson ?</div>
        <div className="delete-drink-popup-buttons">
          <button className="delete-drink-popup-button" onClick={props.onClose}>Non</button>
          <button className="delete-drink-popup-button" onClick={handleDelete}>Oui</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteDrink;