import './styles/menu_page.css'
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { firebaseDb } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import ButtonStatus from "../composants/buttons/button_status.js";
import ButtonCart from "../composants/buttons/button_cart.js";
import ButtonMenu from "../composants/buttons/button_menu.js";

/* import images */
import logo2 from './images/barashots_logo_2.png'

/**
* Page représentant le menu du bar côté client
*/
const MenuPage = () => {
  // Variables pour la redirection et les query dans Firebase
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get('id');
  const url = location.pathname.split('/');
  const barName = url[1];
  const Navigate = useNavigate();


  // Récupération du panier dans le localStorage
  const storedList = localStorage.getItem('cart');
  var list = storedList || '{"cart":{}}';

  // Etats react
  var [status,] = useState(""); // Etat du statut de la commande
  var [cart, setCart] = useState(JSON.parse(list)); // Etat du panier
  const [menu, setMenu] = useState([]); // Etat du menu
  const [isLoading, setIsLoading] = useState(true); // Etat du chargement


  // Récupère les commandes de la base de données
  const LoadOrders = async () => {
    try {
      // On récupère toutes les commandes du bar
      const collectionRef = collection(firebaseDb, 'BaraShots_Bara');
      const q = query(collectionRef, where('name', '==', barName));
      const response = await getDocs(q);
      if (response.empty || response.size > 1) {
        return [];
      } else {
        // Sauve les commandes dans un tableau
        const barID = response.docs[0].id;
        const ordersData = await getDocs(collection(firebaseDb, "BaraShots_Bara", barID.toString(), "Orders"))
          .then((result) => {
            const ordersData = [];
            result.forEach((doc) => {
              ordersData.push(doc.data());
            });
            return ordersData;
          });
        return ordersData;
      }
    } catch (error) {
      console.error("Error fetching orders data:", error);
      throw error;
    }
  };

  // Groupe les boissons par catégorie pour pouvoir les afficher
  function groupDrinks(menu) {
    const tmp = {};
    menu.forEach((drink) => {
      if (!tmp[drink.Category]) {
        tmp[drink.Category] = [];
      }
      tmp[drink.Category].push(drink);
    });
    return tmp;
  }

  // Liste des boissons à récupérer depuis la base de données
  const LoadMenu = async () => {
    // On récupère le panier dans le localStorage
    setCart(prevCart => ({ ...prevCart, ...JSON.parse(localStorage.getItem("cart")) }));
    // On récupère toutes les boissons du bar
    const collectionRef = collection(firebaseDb, 'BaraShots_Bara');
    const q = query(collectionRef, where('name', '==', barName));
    const response = await getDocs(q);
    if (response.empty || response.size > 1) {
      setIsLoading(false);
      return;
    } else {
      // Sauve les boissons dans un tableau
      const barID = response.docs[0].id;
      const menuData = [];
      getDocs(collection(firebaseDb, "BaraShots_Bara", barID.toString(), "Menu"))
        .then((result) => {
          result.forEach((doc) => {
            menuData.push(doc.data());
            // Ajoute seulement le nom au panier si il n'existe pas
            if (cart[doc.data().Name] === undefined) {
              cart[doc.data().Name] = 0;
            }
          });
          // Set les états
          setMenu(groupDrinks(menuData));
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching menu data:", error);
          setIsLoading(false);
        });
    }
  };

  // Ajoute 1 à la quantité de boisson 'item' au panier
  function buttonAddDrink(item) {
    const updatedCart = {
      ...cart,
      [item.Name]: (cart[item.Name] || 0) + 1
    };
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  }

  // Enlève 1 à la quantité de boisson 'item' au panier
  function buttonRemoveDrink(item) {
    const updatedCart = {
      ...cart,
      [item.Name]: Math.max((cart[item.Name] || 0) - 1, 0)
    };
    if (updatedCart[item.Name] === 0) {
      delete updatedCart[item.Name];
    }
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  }

  // Récupère le statut de la commande du transat

  useEffect(() => {
    // Redirection si pas de transat précisé
    if (!id) {
      Navigate("/");
      return;
    }
    LoadMenu();
    LoadOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // HTML de la page
  return (
    <div className="all">

      {status && <ButtonStatus status={status} />}

      <header className="header">
        <div className="logo">
          <img src={logo2} alt="BaraShots Logo" />
        </div>
        <div className="title">
          <h1>BaraShots</h1>
          <p>Commandez en toute simplicité</p>
          <p>depuis votre transat</p>
        </div>
      </header>

      <div className="menu-line">
        <div className="menu">Menu</div>
        <div className="transat">Transat n⁰ {id}</div>
      </div>

      {
        isLoading ? ( /* Si le chargement est en cours */
          <p>Loading...</p>
        ) : ( /* Si le chargement est terminé */
          Object.keys(menu).map((Category) => (
            <div key={Category}>
              <div className="section-title">
                <div className="menu-title">{Category}</div>
                <div className="line"></div>
              </div>

              {menu[Category].map((drink) => (
                <div key={drink.Name} className="cocktail-specs">
                  <div className="cocktail-image">
                    <img src={drink.ImageRef} alt="Cocktail" />
                  </div>
                  <div className="cocktail-price">
                    <div className="price">{drink.Price}.-</div>
                  </div>
                  <div className="cocktail-details">
                    <div className="cocktail-name">{drink.Name}</div>
                    <div className="ingredients">{drink.Description}</div>
                  </div>
                  <div className="quantity">
                    <button className="increment" onClick={() => buttonAddDrink(drink)}>+</button>
                    <span className="count">{cart[drink.Name] || 0}</span>
                    <button className="decrement" onClick={() => buttonRemoveDrink(drink)}>-</button>
                  </div>
                </div>
              ))}
            </div>
          ))
        )
      }

      <div className="client-footer">
        <ButtonMenu cart={cart} id={id} underlined={true} />
        <div className="vl"></div>
        <ButtonCart cart={cart} id={id} underlined={false} />
      </div>
    </div>
  );
};

export default MenuPage;