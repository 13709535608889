import React from "react";
import { useNavigate } from "react-router-dom";
import { firebaseAuth } from "../firebase";
import { signInWithEmailAndPassword, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getBarName, getBarmanName } from '../composants/accreditations';

/**
 * La page login permet de se connecter à l'application
 */
const LoginPage = () => {

  // Fonction qui permet de se connecter à l'application
  const onClick = () => {
    const email = document.getElementById('email').value;
    const password = document.getElementById('psw').value;

    setPersistence(firebaseAuth, browserSessionPersistence)
      .then(() => {
        signInWithEmailAndPassword(firebaseAuth, email, password)
          .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            // redirigé vers la page admin ou barman
            redirectFromEmail(email);
            alert("User signed-in successfully:\n" + user.email + "\n" + user.uid);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert("Error:\n " + errorMessage + "\n" + errorCode);
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert("Error:\n " + errorMessage + "\n" + errorCode);
      });
  }

  const history = useNavigate();

  // cette fonction permet de rediriger l'utilisateur vers la page admin ou barman
  function redirectFromEmail(emailAddress) {
    const barName = getBarName();
    const barmanName = getBarmanName();

    let newUrl = "";

    if (barmanName === "admin") {
      newUrl = `/${barName}/admin`;
      history(newUrl);
      return;
    } else {
      newUrl = `/${barName}/barman/menu`;
      history(newUrl);
    }
  }

  // HTML du formulaire de login
  return (
    <div>
      <form>
        <div>
          <h1>Page de Login</h1>
          <p>Remplissez ce formulaire pour vous Log in.</p>
          <label for="email"><b>Email</b></label>
          <input type="text" placeholder="Enter Email" name="email" id="email" required />
          <br />
          <label><b>Mot de passe</b></label>
          <input type="password" placeholder="Password" name="psw" id="psw" required />
          <p><button type="button" onClick={onClick}>Login</button></p>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;