import './styles/cart_page.css'
import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { firebaseDb } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';


/* import images */
import ButtonMenu from "../composants/buttons/button_menu.js";
import ButtonStatus from "../composants/buttons/button_status.js";
import ButtonCart from "../composants/buttons/button_cart.js";
import PopupPayment from "../composants/popups/payment_method.js";
import logo2 from './images/barashots_logo_2.png'
import time_left from './images/time-left.png'
import menu_logo from '../pages/images/menu.png'

/**
* Page représentant le panier du client
*/
const CartPage = () => {
  // Variables pour la redirection et les query dans Firebase
  const location = useLocation();
  const url = location.pathname.split('/');
  const barName = url[1];
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get('id');

  // Liste des boissons dans le panier temporaire récupérée depuis le local storage
  const storedList = localStorage.getItem('cart');
  var list = storedList || '{"cart":{}}';

  // Etats React
  const [basket, setBasket] = useState([]); // Panier avec les boissons détaillées affichées dans la liste
  var [cart, setCart] = useState(JSON.parse(list)); // Panier avec les boissons sans détails et quantités pour les commandes et le local storage
  const [, setIsLoading] = useState(true); // Chargement de la page
  var [status, setStatus] = useState(""); // Statut de la commande
  const [popupPayment, setPopupPayment] = useState(false); // Popup de paiement
  const [isRefreshing, setIsRefreshing] = useState(false); // Rafraîchissement de la page


  // Récupère les commandes de la base de données
  const LoadOrders = useCallback(async () => {
    try {
      // On récupère toutes les commandes du bar
      const collectionRef = collection(firebaseDb, 'BaraShots_Bara');
      const q = query(collectionRef, where('name', '==', barName));
      const response = await getDocs(q);
      if (response.empty || response.size > 1) {
        return [];
      } else {
        // Sauve les commandes dans un tableau
        const barID = response.docs[0].id;
        const ordersData = await getDocs(collection(firebaseDb, "BaraShots_Bara", barID.toString(), "Orders"))
          .then((result) => {
            const ordersData = [];
            result.forEach((doc) => {
              ordersData.push(doc.data());
            });
            return ordersData;
          });
        return ordersData;
      }
    } catch (error) {
      console.error("Error fetching orders data:", error);
      throw error;
    }
  }, [barName]);

  // Récupère la liste des boissons depuis la base de données
  const LoadMenu = useCallback(async () => {
    try {
      // On récupère toutes les boissons du bar
      const collectionRef = collection(firebaseDb, 'BaraShots_Bara');
      const q = query(collectionRef, where('name', '==', barName));
      const response = await getDocs(q);
      if (response.empty || response.size > 1) {
        setIsLoading(false);
        return [];
      } else {
        // Sauve les boissons dans un tableau
        const barID = response.docs[0].id;
        const menuData = await getDocs(collection(firebaseDb, "BaraShots_Bara", barID.toString(), "Menu"))
          .then((result) => {
            const menuData = [];
            result.forEach((doc) => {
              menuData.push(doc.data());
            });
            return menuData;
          });
        return menuData;
      }
    } catch (error) {
      console.error("Error fetching menu data:", error);
      throw error;
    }
  }, [barName]);

  // Récupère les boissons du panier depuis la base de données
  const LoadBasket = useCallback(async () => {
    try {
      // Appel de la fonction quand le composant est monté
      const menu = await LoadMenu();

      // On récupère le panier depuis le local storage
      const storedList = localStorage.getItem("cart") || '{}';
      const parsedCart = JSON.parse(storedList);

      // Vérifie que le panier est bien un objet itérable
      if (parsedCart && typeof parsedCart === 'object') {
        const newBasket = [];

        // For each drink in the cart, add drink details
        for (const [drink, count] of Object.entries(parsedCart)) {
          for (const item of menu) {
            if (item.Name === drink && count > 0) {
              newBasket.push({ name: drink, count: count, price: item.Price, description: item.Description, image: item.ImageRef });
            }
          }
        }
        // Update the basket state
        setBasket(newBasket);

        // Toutes les boissons sont chargées, on a fini de charger la page
        setIsLoading(false);
      } else {
        console.error("Cart is not defined or not iterable.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }, [LoadMenu]);

  // Ajoute 1 à la quantité de boisson 'item' au panier
  function buttonAddDrink(item) {
    setCart((prevCart) => ({
      ...prevCart,
      [item.name]: (prevCart[item.name] || 0) + 1
    }));
    //Met à jour le local storage
    localStorage.setItem("cart", JSON.stringify(cart));
    item.count = (item.count || 0) + 1;
  }

  // Enlève 1 à la quantité de boisson 'item' au panier, ou supprime la boisson si la quantité est 0
  function buttonRemoveDrink(item) {
    item.count = Math.max((item.count || 0) - 1, 0);
    if (item.count === 0) {
      setBasket((prevBasket) => prevBasket.filter(drink => drink.name !== item.name)
      );
    }

    setCart((prevCart) => ({
      ...prevCart,
      [item.name]: Math.max((prevCart[item.name] || 0) - 1, 0)
    }));

    //Met à jour le local storage
    localStorage.setItem("cart", JSON.stringify(cart));
  }

  // Ouvre et ferme le popup de paiement
  const openPopupPayment = () => {
    setPopupPayment(true);
  };
  const closePopupPayment = () => {
    setPopupPayment(false);
  };



  // Callback function pour afficher le statut de la commande
  function handleOrderPlacement(status) {
    if (status === 'success') {
      setIsRefreshing(true); // Rafraîchit la page
    }
  }

  // Vide le panier
  function emptyCart() {
    setBasket([]);
    setCart({});
    localStorage.setItem("cart", JSON.stringify({}));
  }

  // Valide la commande et l'ajoute à la base de données
  function validateOrder() {
    // Si le panier est vide, ou si il y a une commande en cours, on ne fait rien
    if (basket.length === 0 || status) {
      return;
    }
    // Ouvre le popup de paiement
    openPopupPayment();
    return;
  }

  // Récupère le statut de la commande du transat
  useEffect(() => {
    var orders = LoadOrders(); // Appel de la fonction quand le composant est monté
    orders.then((result) => {
      result.forEach((order) => {
        // Si la commande est celle du transat et qu'elle n'est pas terminée, on met à jour le statut
        if (order.Table === id && order.Statusbar !== "Done") {
          setStatus(order.Statusbar);
        }
      });
    });
    setCart((prevCart) => ({ ...prevCart, ...JSON.parse(localStorage.getItem("cart")) }));
    LoadBasket();
    setIsRefreshing(false);
  }, [LoadOrders, id, LoadBasket, isRefreshing]);


  // HTML de la page
  return (
    <div className="all">

      {status && <ButtonStatus status={status} />}

      <header className="header">
        <div className="logo">
          <img src={logo2} alt="BaraShots Logo" />
        </div>
        <div className="title">
          <h1>BaraShots</h1>
          <p>Commandez en toute simplicité</p>
          <p>depuis votre transat</p>
        </div>
      </header>

      <div className="menu-line">
        <div className="menu">Panier</div>
        <div className="transat">Transat n⁰ {id}</div>
      </div>

      {/* Si il n'y a aucune boisson dans le panier, afficher un message */
        basket.length === 0 ?
          <div className="messages">
            <p className="message">
              Votre panier est vide ! Ajoutez des boissons via le menu
              <img src={menu_logo} alt="basket Logo" />
            </p>
            {status && <p className="message">
              Ou consultez le status de votre commande via le menu
              <img src={time_left} alt="basket Logo" />
            </p>}
          </div>
          : null
      }
      {/* Si il y a des boisson, afficher la liste */
        (basket.map((drink) => (
          <div key={drink.name} className="cocktail-specs">
            <div className="cocktail-image">
              <img src={drink.image} alt="Cocktail" />
            </div>
            <div className="cocktail-price">
              <div className="price">{drink.price}.-</div>
            </div>
            <div className="cocktail-details">
              <div className="cocktail-name">{drink.name}</div>
              <div className="ingredients">{drink.description}</div>
            </div>
            <div className="quantity">
              <button className="increment" onClick={() => buttonAddDrink(drink)}>+</button>
              <span className="count">{drink.count || 0}</span>
              <button className="decrement" onClick={() => buttonRemoveDrink(drink)}>-</button>
            </div>
          </div>
        )))
      }

      <div className="total">
        <div className="total-line"></div>
        <div className="total-text">Total commande :
          <span className="total-price">
            {
              basket.reduce((accumulator, drink) => {
                const price = parseFloat(drink.price); // Parse the price as a float (assuming it's a string)
                const count = drink.count;
                return accumulator + (price * count);
              }, 0)
            }.-
          </span>
        </div>
        <div className="cart-button-container">
          <button className="cart-button" onClick={() => emptyCart()}>Vider le panier</button>
          <button className={basket.length === 0 || status ? "cart-button disabled" : "cart-button"} onClick={() => validateOrder()} >Commander</button>
        </div>
      </div>


      <div className="client-footer">
        <ButtonMenu cart={cart} id={id} underlined={false} />
        <div className="vl"></div>
        <ButtonCart cart={cart} id={id} underlined={true} />
      </div>
      {popupPayment && <PopupPayment onClose={closePopupPayment} barName={barName} id={id} cart={cart} basket={basket} emptyCart={emptyCart} onOrderPlaced={handleOrderPlacement} />}
    </div>
  );
};

export default CartPage;