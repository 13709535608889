import React, { useState } from 'react';
import './styles/payment_method.css';
import { firebaseDb } from '../../firebase';
import { collection, query, where, getDocs, addDoc, Timestamp } from 'firebase/firestore';

/**
* Ce popup permet de demander au client comment il souhaite payer
*
* props : onClose, barName, id, cart, basket, emptyCart, onOrderPlaced
* onClose : fonction pour fermer le popup
* barName : nom du bar
* id : numéro de la table
* cart : panier
* basket : menu
* emptyCart : fonction pour vider le panier
* onOrderPlaced : fonction pour afficher le message de confirmation
*/
const PopupPaymentMethod = ({ onClose, barName, id, cart, basket, emptyCart, onOrderPlaced }) => {
  var [chosen, setChosen] = useState(false);

  const handlePayment = (payment) => {
    // Permet de cacher les boutons de paiement
    setChosen(true);

    // Récupère la collection où ajouter la commande dans Firebase
    const collectionRef = collection(firebaseDb, "BaraShots_Bara");
    const q = query(collectionRef, where("name", "==", barName));
    var barID = "";
    getDocs(q).then((response) => {
      if (response.empty) {
        console.error('No bar found from the URL.');
        return;
      } else {
        if (response.size > 1) {
          console.error('More than one bar found from the URL.');
          return;
        } else {
          barID = response.docs[0].id;
          // Récupère le timestamp actuel
          var timestamp = Timestamp.now();
          // Crée un document avec les informations de la commande
          var docData = {
            Statusbar: "Waiting",
            Payment: payment,
            Table: id,
            Begin: timestamp,
            Drinks: {}
          };
          // Calcule le total de la commande
          var total = 0;
          for (const [drink, count] of Object.entries(cart)) {
            for (const item of basket) {
              if (item.name === drink && count > 0) {
                total += parseFloat(item.price) * count;
                docData["Drinks"][drink] = count;
              }
            }
          }
          docData["Total"] = total;

          // Ajoute la commande à la base de données
          addDoc(collection(firebaseDb, "BaraShots_Bara", barID.toString(), "Orders"), docData).then((result) => {
          });

          // Vide le panier après avoir validé la commande
          emptyCart();
          onOrderPlaced("success");
        }
      }
    });
  }

  // HTML du popup
  return (
    <div className="pay-popup-overlay">
      <div className="pay-popup-content">
        <button className="pay-close-button" onClick={onClose}>X</button>
        {!chosen && <div className="pay-popup-title">Comment souhaitez-vous payer ?</div>}
        {!chosen && <div className="pay-popup-buttons">
          <button className="pay-popup-button" onClick={() => handlePayment("cash")}>Espèces</button>
          <button className="pay-popup-button" onClick={() => handlePayment("card")}>Carte bancaire</button>
        </div>}
        {chosen && <div className="pay-popup-message"> Merci de votre commande ! Un serveur viendra encaisser dès qu'il sera disponible</div>}
      </div>
    </div>
  );
};

export default PopupPaymentMethod;
