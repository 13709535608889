import React from "react";
import "./styles/add_drink.css";
import { firebaseDb, firebaseStorage } from "../../firebase";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { useLocation } from "react-router-dom";


/* import images */
import temp from '../../pages/images/camera.png';

/**
* Ce popup est utilisé pour ajouter une boisson au menu
*
* props : onClose
* onClose : fonction pour fermer le popup
*/
const AddDrink = (props) => {

  const location = useLocation();

  // separe l'url pour recuperer le nom du bar
  const url = location.pathname.split("/");

  const barName = url[1];

  const registerDrink = (event) => {
    event.preventDefault();

    const collectionRef = collection(firebaseDb, "BaraShots_Bara");

    // recherche le bar dans la base de donnée
    const q = query(collectionRef, where("name", "==", barName));
    var barID = "";
    getDocs(q).then((response) => {
      if (response.empty) {
        console.log('No bar found from the URL.');
        return;
      } else {
        if (response.size > 1) {
          console.log('More than one bar found from the URL.');
          return;
        } else {
          barID = response.docs[0].id;

          // recuperation de l'image
          const fileInput = document.getElementById('image').files[0];
          const storageRef = ref(firebaseStorage, barID + '/' + fileInput.name);
          // Charge l'image dans le storage
          uploadBytes(storageRef, fileInput).then((snapshot) => {

            // recuperation de l'url de l'image
            getDownloadURL(storageRef).then((url) => {

              // creation du document
              var docData = {
                Name: document.getElementById('name').value,
                Price: document.getElementById('price').value,
                Description: document.getElementById('description').value,
                Category: document.getElementById('category').value,
                ImageRef: url
              };

              // ajout du document dans la base de donnée
              addDoc(collection(firebaseDb, "BaraShots_Bara", barID.toString(), "Menu"), docData).then((result) => {
                console.log("Document written with ID: ", result.id);
                window.location.reload();
                props.onClose();
              });
            });
          });
        }
      }
    })

  }

  // charge l'image dans le popup
  function loadPic(e) {
    const image = document.getElementById('preview');
    image.src = URL.createObjectURL(e.target.files[0]);
    image.onload = function () {
      URL.revokeObjectURL(image.src)
    }
  }


  // HTML du popup
  return (
    <div className="container">
      <div className="popup-overlay">
        <div className="popup-content">
          <form >
            <div className="input_box">
              <label  >Nom :</label>
              <input type="text" id="name" />
            </div>
            <div className="input_box">
              <label >Descr. :</label>
              <input id="description" className="input_description" cols="40" rows="5"></input>
            </div>
            <div className="input_box">
              <label  >Prix :</label>
              <input type="number" step="any" id="price" />
            </div>

            <div className="cat">
              <label className="drink_label" >Catégories :</label>
              <select id="category" className="scrolling-menu">
                <option value="Cocktail">Cocktail</option>
                <option value="Biere">Bière</option>
                <option value="Shots">Shots</option>
                <option value="Autres">Autres</option>
              </select>
            </div>
            <div className="input_box">
              <input type="file" id="image" name="image" accept="image/*" onChange={loadPic} />
            </div>
            <div className="upload-img">
              <img className="upload-img" id="preview" src={temp} alt="preview" />
            </div>

            <div className="buttons_bottom">
              <button id="closePopup" onClick={props.onClose}>
                Annuler
              </button>
              <button type="submit" onClick={registerDrink}>Valider</button>

            </div>

          </form>

        </div>
      </div>
    </div>
  );
}

export default AddDrink;