import React, { useState } from 'react';
import DeleteDrink from '../popups/delete_drink';

/* import images */
import trashcan from '../../pages/images/trashcan.png'

/**
* Ce bouton est utilisé pour supprimer une boisson du panier
* props : drink
* drink : la boisson à supprimer
*/
const ButtonDeleteDrink = (props) => {
  const [isOpen, setIsOpen] = useState(false);


  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  // HTML du bouton
  return (
    <div className="edit">
      <img src={trashcan} alt={"Suppression d'une boisson"} onClick={openPopup} />
      {isOpen && (<DeleteDrink drink={props.drink} onClose={closePopup} />)}
    </div>
  );
};

export default ButtonDeleteDrink;

