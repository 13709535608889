import React from 'react';
import './styles/404.css'

/**
* Représente la page 404
*/
export default function NotFound() {
  return (
    <div id="message">
      <h2>404</h2>
      <h1>Page Not Found</h1>
      <p>The specified file was not found on this website. Please check the URL for mistakes and try again.</p>
    </div>
  );
}