import { firebaseAuth } from '../firebase';

// cette fonction permet de vérifier si l'utilisateur est autorisé à accéder à la page
function isAccountAuthorised(barName, adminRequired = false) {
  const user = firebaseAuth.currentUser;

  if (user !== null) {
    const email = user.email;

    // utilisation d'une expression régulière pour vérifier le format de l'email
    const regex = /\.(\w+)@barashots\.ch/;
    const match = email.match(regex);


    if (match) {
      const emailBarName = match[1];

      // Compare the barname in the email with the barname in the URL
      if (emailBarName !== barName) {
        return false;
      }
      if (adminRequired && email.split('@')[0] !== 'admin') {
        return false;
      }
    } else {
      return false; // l'email n'est pas au bon format
    }
  } else {
    return false; // l'utilisateur n'est pas connecté
  }

  return true;
}

// cette fonction permet de récupérer le nom du bar
function getBarName() {
  const user = firebaseAuth.currentUser;
  if (user !== null) {
    const email = user.email;

    const regex = /\.(\w+)@barashots\.ch/;
    const match = email.match(regex);

    if (match) {
      const barName = match[1];
      return barName;
    }
  }

  return '';
}

// cette fonction permet de récupérer le nom du barman
function getBarmanName() {
  const user = firebaseAuth.currentUser;
  if (user !== null) {
    // The user object has basic properties such as display name, email, etc.
    const email = user.email;

    // Define a regular expression to match the name before the first dot
    const regex = /^([^.]+)\./; // Remove the unnecessary escape character
    const match = email.match(regex);
    console.log("match", match);

    if (match) {
      const barmanName = match[1]; // Extract the matched barman name
      return barmanName;
    }
  }

  return '';
}

export { isAccountAuthorised, getBarmanName, getBarName };