import React from 'react';
import { Routes, Route } from "react-router-dom";
import LandingPage from './pages/landing_page';
import MenuPage from './pages/menu_page';
import CartPage from './pages/cart_page';
import SignupPage from './pages/signup_page';
import LoginPage from './pages/login_page';
import AdminPage from './pages/admin_page';
import OrdersPage from './pages/orders';
import OrderPage from './pages/order';
import StatsPage from './pages/stats_page';
import NotFoundPage from './pages/404';
import MenuPageBarman from './pages/edit_menu';
import Contact from './pages/contact';

export default function App() {

  const Bars = ["chillout", "bartil"];
  // return a div with all the routes

  // Generate an array of Route elements for each bar
  const barRoutes = Bars.map((bar) => (
    <React.Fragment key={bar}>
      <Route path={bar + "/client/menu"} element={<MenuPage />} />
      <Route path={bar + "/client/cart"} element={<CartPage />} />
      <Route path={bar + "/barman/orders"} element={<OrdersPage />} />
      <Route path={bar + "/barman/order"} element={<OrderPage />} />
      <Route path={bar + "/barman/stats"} element={<StatsPage />} />
      <Route path={bar + "/barman/menu"} element={<MenuPageBarman />} />
      <Route path={bar + "/admin"} element={<AdminPage />} />
    </React.Fragment>
  ));

  // Return a div with all the routes
  return (
    <div>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {barRoutes}
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );

};





